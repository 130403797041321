<template>
  <div
    style="background-color: white; height: 90%; width: 100%; overflow-x: auto"
  >
    <h1>다축 그래프</h1>
    <!-- ECharts 그래프를 렌더링할 div -->
    <div ref="multiAxisChart" style="width: 70%; height: 800px"></div>
    <h1>온도-전력량 및 습도-전력량 산포도 비교</h1>
    <!-- ECharts 그래프를 렌더링할 div -->
    <div ref="scatterChart" style="width: 70%; height: 800px"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "MultiAxisChart",
  data() {
    return {
      // X축 및 시리즈에 사용할 데이터
      days: Array.from({ length: 30 }, (_, i) => `Day ${i + 1}`),
      temperatureData: [
        22, 23, 19, 24, 21, 22, 20, 23, 25, 26, 21, 19, 20, 22, 24, 25, 26, 23,
        24, 25, 19, 22, 21, 23, 24, 20, 19, 21, 23, 22,
      ],
      energyUsageData: [
        120, 132, 101, 134, 90, 230, 210, 180, 165, 170, 142, 156, 145, 180,
        160, 170, 180, 190, 130, 140, 150, 160, 190, 200, 210, 230, 180, 140,
        130, 170,
      ],
      humidityData: [
        50, 52, 49, 53, 54, 55, 56, 57, 58, 60, 59, 57, 56, 55, 53, 54, 55, 56,
        57, 58, 60, 61, 59, 58, 57, 56, 55, 53, 52, 51,
      ],
    };
  },
  mounted() {
    this.renderChart1();
    this.renderChart2();
  },
  methods: {
    renderChart1() {
      const chartDom = this.$refs.multiAxisChart;
      const myChart = echarts.init(chartDom);

      // ECharts 옵션 설정
      const option = {
        title: {
          text: "온도, 에너지 사용량 및 습도 시각화",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        legend: {
          data: ["온도 (°C)", "에너지 사용량 (kWh)", "습도 (%)"],
        },
        xAxis: {
          type: "category",
          data: this.days,
        },
        yAxis: [
          {
            type: "value",
            name: "온도 (°C)",
            position: "left",
            axisLine: {
              lineStyle: {
                color: "red",
              },
            },
          },
          {
            type: "value",
            name: "에너지 사용량 (kWh)",
            position: "right",
            axisLine: {
              lineStyle: {
                color: "blue",
              },
            },
          },
          {
            type: "value",
            name: "습도 (%)",
            position: "right",
            offset: 60, // 오른쪽으로 60px 추가 오프셋 적용
            axisLine: {
              lineStyle: {
                color: "green",
              },
            },
          },
        ],
        series: [
          {
            name: "온도 (°C)",
            type: "line",
            data: this.temperatureData,
            yAxisIndex: 0, // 첫 번째 Y축 사용 (온도)

            smooth: true, // 곡선 라인
          },
          {
            name: "습도 (%)",
            type: "line",
            data: this.humidityData,
            yAxisIndex: 1, // 세 번째 Y축 사용 (습도)

            smooth: true, // 곡선 라인
          },
          {
            name: "에너지 사용량 (kWh)",
            type: "bar",
            data: this.energyUsageData,
            yAxisIndex: 2, // 두 번째 Y축 사용 (에너지 사용량)
          },
        ],
      };

      // 차트에 옵션을 적용하여 그리기
      myChart.setOption(option);
    },
    renderChart2() {
      const chartDom = this.$refs.scatterChart;
      const myChart = echarts.init(chartDom);

      // 온도-전력량 산포도 데이터 (각 점을 [온도, 전력량] 쌍으로 구성)
      const tempEnergyScatter = this.temperatureData.map((temp, index) => [
        temp,
        this.energyUsageData[index],
      ]);

      // 습도-전력량 산포도 데이터 (각 점을 [습도, 전력량] 쌍으로 구성)
      const humidityEnergyScatter = this.humidityData.map((humidity, index) => [
        humidity,
        this.energyUsageData[index],
      ]);

      // ECharts 옵션 설정
      const option = {
        title: {
          text: "온도-전력량 및 습도-전력량 산포도 비교",
        },
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            return `${params.seriesName}<br/>X: ${params.value[0]}<br/>Y: ${params.value[1]}`;
          },
        },
        legend: {
          data: ["온도-전력량", "습도-전력량"],
        },
        xAxis: {
          type: "value",
          name: "온도 (°C) / 습도 (%)",
          scale: true,
        },
        yAxis: {
          type: "value",
          name: "전력량 (kWh)",
          scale: true,
        },
        series: [
          {
            name: "온도-전력량",
            type: "scatter",
            data: tempEnergyScatter,
            itemStyle: {
              color: "red",
            },
            symbolSize: 10,
          },
          {
            name: "습도-전력량",
            type: "scatter",
            data: humidityEnergyScatter,
            itemStyle: {
              color: "blue",
            },
            symbolSize: 10,
          },
        ],
      };

      // 차트에 옵션을 적용하여 그리기
      myChart.setOption(option);
    },
  },
};
</script>

<style scoped>
/* 필요한 경우 추가 스타일을 여기에 작성 */
</style>
